import React, { MutableRefObject, useCallback } from 'react';
import CustomSmartBoard from '@components/Widgets/components/CustomSmartBoard';
import {
  BoardPostDefaultValue,
  BoardPostType,
  BoardSlidesDefaultValue,
  WidgetTypes,
} from '@typedef/Widget/widget.types';
import Slider from 'react-slick';

type Props = {
  selectLayout: WidgetTypes | null;
  handleOnChange: (id: string, value: any) => void;
  sliderRef: MutableRefObject<Slider | null>;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
  activeSlide: number;  // 현재 활성화 된 index
};

const CustomSmartBoardContainer: React.FC<Props> = ({
  selectLayout,
  handleOnChange,
  sliderRef,
  setActiveSlide,
  activeSlide,
}) => {
  const handleSizeChange = useCallback((isRow: boolean, plus: boolean) => {
    const slide = activeSlide === 0 ? selectLayout : selectLayout?.boardSlides?.[activeSlide - 1];

    if (slide && Array.isArray(slide.boardFiles) && slide.boardFiles.length > 0) {
      const currentSize = isRow ? slide.boardFiles.length : slide.boardFiles[0].length;
      const newSize = plus ? currentSize + 1 : currentSize - 1;

      if (newSize < 1 || newSize > 10) return;

      let newBoardFiles: BoardPostType[][];

      if (isRow) {
        if (plus) {
          const newRow = Array(slide.boardFiles[0].length).fill(null).map(() => ({ ...BoardPostDefaultValue }));
          newBoardFiles = [...slide.boardFiles, newRow];
        } else {
          newBoardFiles = slide.boardFiles.slice(0, -1);
        }
      } else {
        newBoardFiles = slide.boardFiles.map(row =>
          plus
            ? [...row, { ...BoardPostDefaultValue }]
            : row.slice(0, -1),
        );
      }

      if (activeSlide === 0) {
        handleOnChange('boardFiles', newBoardFiles);
      } else {
        const newBoardSlides = [...(selectLayout?.boardSlides || [])];
        newBoardSlides[activeSlide - 1] = { ...newBoardSlides[activeSlide - 1], boardFiles: newBoardFiles };
        handleOnChange('boardSlides', newBoardSlides);
      }
    }
  }, [activeSlide, selectLayout, handleOnChange]);

  const handleChangeSlide = useCallback((id: string, value: any) => {
    const isMain = activeSlide === 0;

    const newBoardSlides = selectLayout?.boardSlides?.map((slide, index) => {
      if (index === activeSlide - 1) {
        return { ...slide, [id]: value };
      }
      return slide;
    });

    handleOnChange(isMain ? id : 'boardSlides', isMain ? value : newBoardSlides);
  }, [activeSlide, handleOnChange]);

  const handleToggleSlide = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      handleOnChange('boardSlides', [BoardSlidesDefaultValue]);
    } else {
      handleOnChange('boardSlides', []);
    }
  }, []);

  const nextSlide = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [sliderRef]);

  const previousSlide = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  }, [sliderRef]);

  return (
    <CustomSmartBoard
      selectLayout={selectLayout}
      handleChangeSlide={handleChangeSlide}
      handleSizeChange={handleSizeChange}
      handleOnChange={handleOnChange}
      handleToggleSlide={handleToggleSlide}
      nextSlide={nextSlide}
      previousSlide={previousSlide}
      activeSlide={activeSlide}
      setActiveSlide={setActiveSlide}
    />
  );
};

export default CustomSmartBoardContainer;
