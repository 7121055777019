import * as S from './styles/ScheduleStyle';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-calendar-timeline/lib/Timeline.css';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { ko } from 'date-fns/locale';
import { parseISO } from 'date-fns';
import { DeviceTypes } from '@typedef/Device/device.types';
import { ScheduleTypes } from '@typedef/Schedule/schedule.types';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import ReactDropdown, { Option } from 'react-dropdown';
import { DropdownStyle } from '@styles/dropdownStyle';
import { Plus } from '@assets/icons';
import { AccountTypes } from '@typedef/Account/account.types';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { StyledTooltip } from '@components/Main/styles/MainStyle';

const locales = {
  'ko': ko
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type Props = {
  account: AccountTypes;
  onClickManage: (type: '추가' | '수정', selectScheduleId?: string) => void;
  scheduleList: ScheduleTypes[];
  contentList: ContentTypes[];
  deviceList: DeviceTypes[];
  selectDeviceList: { id: string; name: string }[];
  selectDevice: DeviceTypes | undefined;
  setSelectDevice: React.Dispatch<React.SetStateAction<DeviceTypes | undefined>>;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
};

const Schedule = ({
  account,
  onClickManage,
  scheduleList,
  contentList,
  deviceList,
  selectDeviceList,
  selectDevice,
  setSelectDevice,
  setDate,
}: Props) => {
  return (
    <S.Container>
      <header>
        <div className="left ">
          <p className="title">
            <span>스케줄링</span>
          </p>
          <DropdownStyle width={'270px'} fontSize={'20px'} height={'36px'}>
            <ReactDropdown
              className="react-dropdown-main"
              menuClassName="react-dropdown-menu"
              controlClassName="react-dropdown-control"
              options={selectDeviceList.map((v) => {
                return { label: v.name, value: v.id };
              })}
              onChange={(value: Option) => {
                setSelectDevice(deviceList.find((device) => device.id === value.value)!);
              }}
              value={selectDevice ? selectDevice.id.toString() : undefined}
              placeholder={'장비를 선택해주세요'}
            />
          </DropdownStyle>
        </div>
        <div className="btns">
          {!EAccountRole.accessRole(account.role) ?
            <StyledTooltip title={'해당 기능에 접근 권한이 없습니다.'} placement={'bottom'}>
              <button disabled={true}>
                <Plus />
                스케줄 추가
              </button>
            </StyledTooltip> :
            <button onClick={() => onClickManage('추가')} disabled={deviceList.length <= 0}>
              <Plus />
              스케줄 추가
            </button>
          }
        </div>
      </header>
      <S.TimeLineContainer>
        <Calendar
          events={scheduleList.map((schedule) => {
            const startDateTime = `${schedule.startDate} ${!schedule.repetitionType.equals(EScheduleRepetitionType.ONCE) ? schedule.dailyStartTime : schedule.startTime}`;
            const endDateTime = `${schedule.endDate} ${!schedule.repetitionType.equals(EScheduleRepetitionType.ONCE) ? schedule.dailyEndTime : schedule.endTime}`;

            return {
              id: schedule.id,
              title: contentList.find((content) => content.id === schedule.contentId)?.name,
              start: parseISO(startDateTime),
              end: parseISO(endDateTime),
            };
          })}
          onSelectEvent={(event) => onClickManage('수정', event.id)}
          localizer={localizer}
          views={['month', 'day']}
          formats={{
            monthHeaderFormat: 'yyyy년 M월',
            dayHeaderFormat: 'yyyy년 M월 d일 eeee',
            dayRangeHeaderFormat: ({ start, end }) =>
              `${format(start, 'yyyy년 M월 d일', { locale: ko })} - ${format(
                end,
                'yyyy년 M월 d일',
                { locale: ko }
              )}`,
            weekdayFormat: 'eeee',
          }}
          messages={{
            week: '주',
            work_week: '업무 주',
            day: '일',
            month: '월',
            previous: '이전',
            next: '다음',
            today: '오늘',
            agenda: '일정',
          }}
          showMultiDayTimes
          step={60}
          style={{ height: 650 }}
          onNavigate={(date: Date) => setDate(date)}
          culture='ko'
        />
      </S.TimeLineContainer>
    </S.Container>
  );
};

export default Schedule;
