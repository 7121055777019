import styled from 'styled-components';

const space = '15px';

export const Container = styled.main`
    header {
        ${({ theme }) => theme.flex.row}
        align-items: center;
        justify-content: space-between;
        margin-bottom: calc(${space} * 2);

        .left {
            ${({ theme }) => theme.flex.row}
            align-items: center;
        }

        .title {
            ${({ theme }) => theme.flex.row}
            align-items: center;

            & > span {
                font-size: 1.5rem;
                font-weight: 600;
                width: 100px;
                background: linear-gradient(to top, #15b9f566 70%, transparent 50%);
            }

            &:after {
                content: ' ';
                width: 3px;
                height: 1rem;
                display: block;
                margin: 0 15px;
                background-color: #191919;
            }
        }

        .react-dropdown-main {
            width: 300px;
        }

        .react-dropdown-control {
            width: 100%;
            padding: 0 15px;
            background-color: #fff;
            border-radius: 5px;

            font-size: 24px;
            font-weight: bold;
        }

        .react-dropdown-menu {
            font-size: 18px;
            border-radius: 5px;
            max-height: 300px;
        }

        .btns {
            width: auto;

            button {
                height: 45px;
                padding: 0 15px;
                background-color: #15b9f5;
                border: 1px solid #15b9f5;
                border-radius: 5px;
                color: #fff;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;

                & > svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }

                &:active {
                    opacity: 0.7;
                }

                &:disabled {
                    background-color: #d3d3d3;
                    color: #fff;
                    cursor: not-allowed;
                }
            }
        }
    }
`;

export const TimeLineContainer = styled.section`
    width: 100%;
    background-color: #fff;
    padding: 15px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    /* 스케줄링 모듈 디자인 변경 */


    .rbc-event {
        border: none;
        border-radius: 3px;
        padding: 4px 5px;
        color: #fff;
        background-color: #55a397;
    }

    .rbc-event-content {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }

    .rbc-month-header {
        height: 50px;
        font-weight: 700 !important;
        font-family: 'Pretendard', 'Apple SD Gothic Neo', serif !important;
    }

    .rbc-header {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        background-color: #2b3e63;




        & > span {
            font-family: 'Pretendard', 'Apple SD Gothic Neo', serif;
            font-weight: 600;
            font-size: 18px;
            color: #fff;
        }
    }

    .rbc-button-link {
        padding: 5px 3px;
    }

    .rbc-row-bg {
        .rbc-day-bg {
            &:first-child {
                background-color: #ec414155;
            }
            
            &:last-child {
                background-color: #4babff55;
            }
        }
    }

    .rbc-month-sunday-bg, .rbc-month-saturday-bg {
        height: calc(100% - 50px);
    }
`;
